body {
  cursor: url("/image/cursor.cur"), pointer;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("/image/background.jpg");
  background-repeat: repeat;
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.gradient {
  background: -webkit-linear-gradient(135deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  -webkit-animation: Gradient 1s ease infinite;
  animation: Gradient 1s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}
.Header {
  text-align: center;
  padding: 40px 0px;
  border: outset 20px cyan;
  color: cyan;
  background: rgba(0, 255, 255, .3);
}

a {
  color: cyan;

}

.spinning-pic {
  -webkit-animation: pic-spin infinite 20s linear;
          animation: pic-spin infinite 20s linear;
}

.bevelBox
{

  padding: 15px;

  font-size: 22px;

  margin-right: 12px;

  background: purple;

  border: 1px solid #000000;
  box-shadow: inset 2px 2px 2px rgba(255, 192, 236, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
}


@-webkit-keyframes pic-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes pic-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



.rainbow {

  /* Font options */
  font-family: 'Pacifico', cursive;
  text-shadow: 2px 2px 4px #000000;
  font-size:40px;

  /* Chrome, Safari, Opera */
  -webkit-animation: rainbow 5s infinite;

  /* Internet Explorer */

  /* Standar Syntax */
  animation: rainbow 5s infinite;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes rainbow{
  0%{color: orange;}
  10%{color: purple;}
  20%{color: red;}
  30%{color: CadetBlue;}
  40%{color: yellow;}
  50%{color: coral;}
  60%{color: green;}
  70%{color: cyan;}
  80%{color: DeepPink;}
  90%{color: DodgerBlue;}
  100%{color: orange;}
}

/* Internet Explorer */

/* Standar Syntax */
@keyframes rainbow{
  0%{color: orange;}
  10%{color: purple;}
  20%{color: red;}
  30%{color: CadetBlue;}
  40%{color: yellow;}
  50%{color: coral;}
  60%{color: green;}
  70%{color: cyan;}
  80%{color: DeepPink;}
  90%{color: DodgerBlue;}
  100%{color: orange;}
}

body{
  background-color:#607D8B;
}

.container{
  background-color:#E0F2F1;
  padding:10px;
  border-radius:15px;
  box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.75);
}
