.Header {
  text-align: center;
  padding: 40px 0px;
  border: outset 20px cyan;
  color: cyan;
  background: rgba(0, 255, 255, .3);
}

a {
  color: cyan;

}

.spinning-pic {
  animation: pic-spin infinite 20s linear;
}

.bevelBox
{

  padding: 15px;

  font-size: 22px;

  margin-right: 12px;

  background: purple;

  border: 1px solid #000000;

  -moz-box-shadow: inset 2px 2px 2px rgba(255, 192, 236, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
  -webkit-box-shadow: inset 2px 2px 2px rgba(255, 192, 236, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
  box-shadow: inset 2px 2px 2px rgba(255, 192, 236, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
}


@keyframes pic-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.rainbow {

  /* Font options */
  font-family: 'Pacifico', cursive;
  text-shadow: 2px 2px 4px #000000;
  font-size:40px;

  /* Chrome, Safari, Opera */
  -webkit-animation: rainbow 5s infinite;

  /* Internet Explorer */
  -ms-animation: rainbow 5s infinite;

  /* Standar Syntax */
  animation: rainbow 5s infinite;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes rainbow{
  0%{color: orange;}
  10%{color: purple;}
  20%{color: red;}
  30%{color: CadetBlue;}
  40%{color: yellow;}
  50%{color: coral;}
  60%{color: green;}
  70%{color: cyan;}
  80%{color: DeepPink;}
  90%{color: DodgerBlue;}
  100%{color: orange;}
}

/* Internet Explorer */
@-ms-keyframes rainbow{
  0%{color: orange;}
  10%{color: purple;}
  20%{color: red;}
  30%{color: CadetBlue;}
  40%{color: yellow;}
  50%{color: coral;}
  60%{color: green;}
  70%{color: cyan;}
  80%{color: DeepPink;}
  90%{color: DodgerBlue;}
  100%{color: orange;}
}

/* Standar Syntax */
@keyframes rainbow{
  0%{color: orange;}
  10%{color: purple;}
  20%{color: red;}
  30%{color: CadetBlue;}
  40%{color: yellow;}
  50%{color: coral;}
  60%{color: green;}
  70%{color: cyan;}
  80%{color: DeepPink;}
  90%{color: DodgerBlue;}
  100%{color: orange;}
}

body{
  background-color:#607D8B;
}

.container{
  background-color:#E0F2F1;
  padding:10px;
  border-radius:15px;
  box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.75);
}